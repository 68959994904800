/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
          
$(window).load(function(){ 
    setTimeout(function(){
        $('.gd-loader').fadeOut();
    }, 100);
});   
     
$(window).load(function(){ 
    setTimeout(function(){
        $('.overlay-content').fadeIn(2000);
    }, 1200);
});        
          
          
// Scroll Reveal
window.sr = ScrollReveal({
    //reset: true,
    opacity: 0,
    scale: 1,
    duration: 1000,
    distance: '100px',
    mobile: false,
    viewFactor: 0.4
});
sr.reveal('.fadein');      
    
 
$('.mobile-toggle').click(function(){
    $('.nav-primary').toggleClass('active');
    $(this).toggleClass('active');
    
    if($('.mobile-toggle').hasClass('active')) {
        $('.mobile-toggle').html('<i class="ion-android-close"></i>'); 
    }
    else {
        $('.mobile-toggle').html('<i class="ion-android-menu"></i>'); 
    }
}); 
     
          
$('.nav li a').click(function(){
    $('.nav-primary').toggleClass('active');
    $('.mobile-toggle').toggleClass('active');
    
});           
         
$('.smooth-scroll, .nav li a').click(function(){
    $('html, body').animate({
        scrollTop: $( $.attr(this, 'href') ).offset().top
    }, 800);
    return false;
}); 
   

$(window).scroll(function() {
    if ($(window).scrollTop() > 400) {
        $('.banner').addClass('scrolling');
    }
    else {
         $('.banner').removeClass('scrolling');
    }
});  
           
          
$(function(){
  var accessToken = '4969729604.1c05c3e.93eec370e8b145a79ffccd62086fb8b1';
  $.getJSON('https://api.instagram.com/v1/users/self/media/recent/?access_token='+accessToken+'&callback=?',function (insta) {
    $.each(insta.data,function (photos,src) {
      if ( photos === 20 ) { 
          return false; 
      }
     
    if (src.caption === null) {
        caption = ''; 
    } else { 
        caption = src.caption.text;
    }
    
     
        $('<li class="col-xs-3 col-sm-3 col-md-3"><a href="'+src.link+'" target="_blank"><figure class="ig-img"><img class="img-fluid" src="'+src.images.standard_resolution.url+'" alt="" /><div class="ig-caption hidden-xs hidden-sm"><div class="ig-content">'+caption+'</div></div></figure></a></li>').appendTo('#ig-sec');  
    });        
  });  
}); 
           
          
          
            
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
          
          
      $('.slider').slick({
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
          fade: true,
          dots: true
      });          
          
          
          
          
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
